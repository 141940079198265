.page-basic {
  width: 100%;
  min-height: 100vh;
}
.page-basic .content {
  margin: 16px 16px 16px 16px;
  padding: 16px;
  background: #fff;
  height: 100%;
  min-height: 280px;
}
